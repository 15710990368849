import React, {useState} from 'react';
import {Button, Form} from "react-bootstrap";

function VideoTemplate({id, name, value, example}) {
		const [show, setShow] = useState(false);

		const copy = () => {
				navigator.clipboard.writeText(value)
				setShow(true)
				setTimeout(() => setShow(false), 3000)
		}

		const isValidId = (id) => id && id.length === 36

		return (
				<>
						<Form.Text className='mb-20' id="deepLinkInputInfo" muted>
								Тип <span className="highlight">{name}</span>
						</Form.Text>
						<Form.Text className='mb-20' id="deepLinkInputInfo" muted>
								Примерный формат ID <span className="highlight">743bd0c0-b95e-4101-9de3-bd27348c6980</span>
						</Form.Text>
						<Form.Text className='mb-20' id="deepLinkInputInfo" muted>
								Шаблон <span className="highlight">{example}</span>
						</Form.Text>
						<Form.Text id="deepLinkInputInfo" className="mb-20" muted>
								Результат:
						</Form.Text>
						<Form.Text id="deepLinkInputInfo" className="highlight mb-20">
								{isValidId(id) && value}
						</Form.Text>
						<Button className="mb-20" disabled={!isValidId(id)} onClick={copy} variant={show ? "success" : "primary"}>
								{show ? "Скопировано" : "Копировать"}
						</Button>
				</>
		);
}

export default VideoTemplate;
