import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './Menu.css';

function BasicExample() {
		return (
				<Navbar expand="lg" className="menu bg-body-tertiary">
						<Container>
								<Navbar.Brand href="#home">DeepLinkMaker</Navbar.Brand>
								<Navbar.Toggle aria-controls="basic-navbar-nav" />
								<Navbar.Collapse id="basic-navbar-nav">
										<Nav className="me-auto">
												<Nav.Link href="/">Ugc контент</Nav.Link>
												<Nav.Link href="/video">Video deepLink</Nav.Link>
										</Nav>
								</Navbar.Collapse>
						</Container>
				</Navbar>
		);
}

export default BasicExample;
