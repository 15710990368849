import React from 'react';
import videoImage from "../../assets/images/png/movie.png";
import "./Card.css";

function Card({name, isSelected = false, onClick}) {
		return (
				<div
						className={"card card-custom " + (isSelected ? "card-custom-active" : "")}
						onClick={value => onClick(value)}
				>
						<img className="card-image" width={"100px"} src={videoImage} alt={"videoImage"}/>
						<span className="card-custom-name">{name}</span>
				</div>
		);
}

export default Card;