const VIDEO_CARD = 1
const SERIAL_CARD = 2
const COLLECTION_CARD = 3


const video = {
		name: "VIDEO",
		id: VIDEO_CARD,
		template: (id) => [
				{
						name: "Наш обычный вариант",
						example: `videos://start?state=%7B+%22command%22%3A+%22WIDGET_MOVIECARD%22%2C+%22contentId%22%3A+%22 какой-то ID %22+%7D+`,
						value: `videos://start?state=%7B+%22command%22%3A+%22WIDGET_MOVIECARD%22%2C+%22contentId%22%3A+%22${id}%22+%7D+`
				},
				{
						name: "Новый META шаблон",
						example: "videos://start?state=%7B+%22command%22%3A+%22WIDGET_META_MOVIECARD%22%2C+%22contentIdMeta%22%3A+%22 какой-то ID %22+%7D",
						value: `videos://start?state=%7B+%22command%22%3A+%22WIDGET_META_MOVIECARD%22%2C+%22contentIdMeta%22%3A+%22${id}%22+%7D`
				},
		]
}

const serial = {
		name: "SERIAL",
		id: SERIAL_CARD,
		template: (id) => [
				{
						name: "Наш обычный вариант",
						example: `videos://start?state=%7B+%22command%22%3A+%22WIDGET_SERIALCARD%22%2C+%22contentId%22%3A+%22 какой-то ID %22+%7D+`,
						value: `videos://start?state=%7B+%22command%22%3A+%22WIDGET_SERIALCARD%22%2C+%22contentId%22%3A+%22${id}%22+%7D+`
				},
		]
}

const collection = {
		name: "COLLECTION",
		id: COLLECTION_CARD,
		template: (id) => [
				{
						name: "Наш обычный вариант",
						example: "videos://start?state=%7B%22command%22%3A%22GO_TO_GALLERY%22%2C%22contentId%22%3A%22 какой-то ID %22%7D",
						value: `videos://start?state=%7B+%22command%22%3A+%22GO_TO_GALLERY%22%2C+%22contentId%22%3A+%22${id}%22+%7D+`
				},
		]
}

const availableDeepLinks = {
		[VIDEO_CARD]: video,
		[SERIAL_CARD]: serial,
		[COLLECTION_CARD]: collection,
}


export {VIDEO_CARD, SERIAL_CARD, COLLECTION_CARD, availableDeepLinks};
