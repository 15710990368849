import React, {useState} from 'react';
import {Container, Row} from "react-bootstrap";
import vkImage from "../../assets/images/png/vk.png";
import vkVideo from "../../assets/images/png/vk_video.svg";
import rutubeImage from "../../assets/images/png/rutube.png";
import TextInput from "../../Components/TextInput/TextInput";
import {availableSettings} from "../../utils";

const VK = 'vk'
const VKVIDEO = 'vkvideo'
const RUTUBE = 'rutube'


function Ugc(props) {
		const [linkType, setLinkType] = useState(VK)

		const setLink = (linkName) => setLinkType(linkName);

		return (
				<Container>
						<Row>
								<div className={"app-links"}>
										<div className={`links-item ${linkType === VK && "active-link"}`} onClick={() => setLink(VK)}>
												<img width={"100px"} src={vkImage} alt={linkType}/>
										</div>
										<div className={`links-item ${linkType === VKVIDEO && "active-link"}`} onClick={() => setLink(VKVIDEO)}>
												<img width={"100px"} src={vkVideo} alt={linkType}/>
										</div>
										<div className={`links-item ${linkType === RUTUBE && "active-link"}`} onClick={() => setLink(RUTUBE)}>
												<img width={"100px"} src={rutubeImage} alt={linkType}/>
										</div>
								</div>
						</Row>

						<Row className="p-20">
								<TextInput link={linkType} settings={availableSettings[linkType]}/>
						</Row>
				</Container>
		);
}

export default Ugc;
