import React, {useState} from 'react';
import {Container, Row, Form} from "react-bootstrap";
import Card from "../../Components/Card/Card";
import {VIDEO_CARD, SERIAL_CARD, COLLECTION_CARD, availableDeepLinks} from "./videoDeepLinks";
import VideoTemplate from "./VideoTemplate";


function Video(props) {
		const [value, setValue] = useState("");
		const [active, setActive] = useState(VIDEO_CARD);

		const handleChange = (event) => setValue(event.target.value);

		return (
				<Container className="video">
						<Row className='p-20 video-select'>
								<Card onClick={() => setActive(VIDEO_CARD)} isSelected={active === VIDEO_CARD} name='Фильм' />
								<Card onClick={() => setActive(SERIAL_CARD)} isSelected={active === SERIAL_CARD} name='Сериал' />
								<Card onClick={() => setActive(COLLECTION_CARD)} isSelected={active === COLLECTION_CARD} name='Коллекция' />
						</Row>
						<Row className="p-20">
								<Form.Label htmlFor="deepLinkInput">ID единицы контента</Form.Label>
								<Form.Control
										className='mb-20'
										type="text"
										id="deepLinkInput"
										aria-describedby="deepLinkInputInfo"
										onChange={event => handleChange(event)}
										value={value}
								/>
								{/* Multiple templates here */}
								{availableDeepLinks?.[active]?.template?.(value).map(item =>
										<VideoTemplate
												key={item.name}
												id={value}
												name={item.name}
												value={item.value}
												example={item.example}
										/>
								)}
						</Row>
				</Container>
		);
}

export default Video;
