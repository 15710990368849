import Menu from "./Components/Menu/Menu";
import Ugc from "./Pages/Ugc/Ugc";
import {Routes, Route} from "react-router";
import Video from "./Pages/Video/Video";
import "./App.css";


function App() {

  return (
    <div className="app">
        <Menu />
        <div className="app-content">
            <Routes>
                <Route index element={<Ugc />} />
                <Route path="video" element={<Video />} />
                <Route default element={<Ugc />} />
            </Routes>
        </div>
    </div>
  );
}

export default App;
